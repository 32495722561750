import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { secureStorage } from "../encryption/securestorage";

@Injectable({
    providedIn: 'root'
})

export class BehaviorDataService {

    public isLoggedIn = new BehaviorSubject<boolean>(false);
    public isUserDetailsUpdated = new BehaviorSubject<boolean>(false);
    public estimationData = new BehaviorSubject<any>(null);
    public estimationDataEdit = new BehaviorSubject<any>(null);
    public dashboardFilter = new BehaviorSubject<any>(null);
    public addressDataChange = new BehaviorSubject<any>(null);
    public bankDataChange = new BehaviorSubject<any>(null);
    public freeSearchTokenUpdated = new BehaviorSubject<any>(null);
    public userLoggedOut = new BehaviorSubject<any>(false);
    public userLoggedIn = new BehaviorSubject<any>(false);
    constructor(){
    this.isLoggedIn = new BehaviorSubject<boolean>(false);
    this.isUserDetailsUpdated = new BehaviorSubject<boolean>(false);
    this.estimationData = new BehaviorSubject<any>(null);
    this.estimationDataEdit = new BehaviorSubject<any>(null)
    this.addressDataChange = new BehaviorSubject<any>(null);
    this.bankDataChange = new BehaviorSubject<any>(null);
    this.dashboardFilter = new BehaviorSubject<any>(null);
    this.freeSearchTokenUpdated = new BehaviorSubject<any>(null);
    this.userLoggedOut = new BehaviorSubject<boolean>(false);
    this.userLoggedIn = new BehaviorSubject<boolean>(false);

    }

}
