import { isPlatformBrowser } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { NavigationEnd, NavigationStart, Router, Event as NavigationEvent } from "@angular/router";
// import { CookieService } from "ngx-cookie";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { ApiUrlList } from "../api-integration-files/api-url-list.service";
import { BehaviorDataService } from "../api-integration-files/behavior-data.service";
import { CookieService } from "../api-integration-files/cookie.service";
import { DataService } from "../api-integration-files/data.service";
import { secureStorage } from "../encryption/securestorage";

@Injectable({
  providedIn: 'root'
})
export class RootService {
  currentRoute: any;
   showServicecall: boolean = false;
  expiredDate: any;
  readonly options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'request-id': 'b997a77ae2b3f30c1bed151c3e42434f',
    }),
  };
  constructor(

        //nonssr
        // private cookieService: CookieService,
        //ssr
        private ssrcookieService: CookieService,
    private behaviorService: BehaviorDataService,
    private router: Router,
    private apiList: ApiUrlList,
    private dataService: DataService,
    private localStorage: secureStorage,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,) { }


  upgradeVersion() {
    if (isPlatformBrowser(this.platformId)) {

    const APP_VERSION = environment?.version;
    if (typeof localStorage.APP_VERSION === 'undefined' || localStorage.APP_VERSION === null) {
      localStorage.clear();
this.deleteCookies();
      localStorage.setItem('APP_VERSION', APP_VERSION);
    }
    if (localStorage.APP_VERSION != APP_VERSION) {
      localStorage.clear();
this.deleteCookies();

      localStorage.setItem('APP_VERSION', APP_VERSION);
    }
  }
  }
deleteCookies(){

  // this.cookieService.remove('access_token');
  // this.cookieService.remove('ref_token');
  // this.cookieService.remove('free_access_token');
  // this.cookieService.remove('free_ref_token');

  this.ssrcookieService.removeItem('access_token');
  this.ssrcookieService.removeItem('ref_token');
  this.ssrcookieService.removeItem('free_access_token');
  this.ssrcookieService.removeItem('free_ref_token');
}
  isUserLoggedIn() {

    // let userCode = JSON.parse(this.localStorage.getItem('userCode'))
    // return userCode ? userCode?.isLoggedIn : false;

    let userCode :any = this.ssrcookieService.getItem('isuserLoggedIn') ? this.ssrcookieService.getItem('isuserLoggedIn')  : null ;
   let isLoggedIn =  (userCode == false || userCode == 'false') ? false :
                     (userCode == true || userCode == 'true') ? true : userCode
    return isLoggedIn ? isLoggedIn : false;

  }
  setUserCode(userCode: any) {
    this.localStorage.setItem('userCode', JSON.stringify(userCode));
    this.ssrcookieService.setItem('isuserLoggedIn', userCode.isLoggedIn);
  }
  getUserCode() {
    return JSON.parse(this.localStorage.getItem('userCode'))?.user_code
  }
  getAccessToken() {
    return this.ssrcookieService.getItem('access_token') == null ? false : true;
  }

  getRefreshToken() {
    return this.ssrcookieService.getItem('ref_token');
  }

  setToken(data: any) {
    // expires_in: 3600
// refresh_expires_in: 7200
    let TodaysDate = new Date();

    let accessMinutes = data.expires_in * 1000;  //3600000
    var accessExpiresIn = (accessMinutes - (accessMinutes / 10))  //3240000
    let accessExpiryDate = new Date(TodaysDate.getTime() + accessExpiresIn);
    let refreshMinutes = data.refresh_expires_in * 1000;
    var refreshExpiresIn = (refreshMinutes - (refreshMinutes / 10))
    let refreshExpiryDate = new Date(TodaysDate.getTime() + refreshExpiresIn);
    // ,'httpOnly':true
    // this.cookieService.putObject('access_token', data.access_token, { 'expires': accessExpiryDate, 'secure':true,sameSite :'none'});
    // this.cookieService.putObject('ref_token', data.refresh_token, { 'expires': refreshExpiryDate, 'secure':true,sameSite :'none'  });
    // ssr
    this.ssrcookieService.setItem('access_token', data.access_token,accessExpiryDate);
    this.ssrcookieService.setItem('ref_token', data.refresh_token);

    // this.getFreeSearchToken();

  }
  setUserData(data: any) {
    this.localStorage.setItem('userData', JSON.stringify(data))
  }

  getUserData() {
    return JSON.parse(this.localStorage.getItem('userData'));
  }

  refreshToken() {
    // let refreshToken = this.cookieService.getObject("ref_token") ? this.cookieService.getObject("ref_token") : '';
    let refreshToken = this.ssrcookieService.getItem("ref_token") ? this.ssrcookieService.getItem("ref_token") : '';
    let requestBody = {
      refresh_token: refreshToken
    }
    let apiUrl: any = this.apiList.returnAPI('refreshToken');
    return this.dataService.post(apiUrl, requestBody).pipe(tap((response: any) => {
      if (response.success) {
        this.setToken(response.data);
      }
    }));
  }

  refreshToken2(): Promise<any> {
    // let refreshToken = this.cookieService.getObject("free_ref_token") ? this.cookieService.getObject("free_ref_token") : this.localStorage.getItem('free_ref_token');
    let refreshToken = this.ssrcookieService.getItem("free_ref_token") ? this.ssrcookieService.getItem("free_ref_token") : this.localStorage.getItem('free_ref_token');
    let data = {}
    // if (refreshToken) {
      data = {
        refresh_token: refreshToken
      }
      // if (!this.showServicecall) {
        this.showServicecall = true;
        const promise1 = this.http.post(`${environment.freeSearchPort}token/refresh/`, data, this.options).toPromise()
          .then((response: any) => {
            this.showServicecall = false;
            if (response['success'] === true) {
              // this.expiredDate = new Date();
              // let Minutes = response.data['expires_in'] * 1000;
              // var expirein = (Minutes - (Minutes / 10))
              // let expiredate2 = new Date(this.expiredDate.getTime() + expirein);

              // this.cookieService.putObject('access_token', response.data['access_token'], { 'expires': expiredate2 });
              // this.cookieService.putObject('ref_token', response.data['refresh_token']);
              // this.localStorage.setItem('ref_token', response.data['refresh_token']);
              this.localStorage.setItem('FreeRefreshTokenInProgress', 'false');
        this.storeJwtToken(response);
            }
            return;
          }).catch(err => {
           return ;
            // if(err.status == 400){
            //     if(err.error.data.error_code==64){
            //

    // this.appStateService.isLoggedIn.next(false);
    // this.appStateService.userSubject.next(null);
    // this.localStorage.clear();
    // this.cookieService.removeAll();
    // this.router.navigate(['']);
            //     }
            // }
        });
        return promise1;
      // }
    // }
  }
  getFreeSearchToken(): Promise<any> {

    // try {

      // let device_id;
      // let deviceid = this.localstorage.getItem('uniqueId');

      // if (deviceid) {
      //   device_id = deviceid;
      // } else {
      //   var result = '';
      //   let random;
      //   var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      //   var charactersLength = characters.length;
      //   for (var i = 0; i < 10; i++) {
      //     result += characters.charAt(Math.floor(Math.random() * charactersLength));

      //   }
      //   random = Date.now() + Math.floor(Math.random() * 100000000000000000000)
      //   random += Math.floor(Math.random() * 100000000000000000000)
      //   random += result
      //   this.localstorage.setItem('uniqueId', random);
      //   device_id = random
      // }

      let user = {
        "device_id": '83596698386586760000fdLHEgDYlE',
      }
      // // RSA Encryption
      // const currentDate = this.getNowUTC();
      // let encryptedCurrentDate = this.encryptAES(String(currentDate));

      // if (this.appStateService.isLoggedIn.value) {
      //   return new Promise((resolve, reject) => {
      //    // resolve()
      //   });
      // }

      // if (!this.showServicecall) {
        this.showServicecall = true;
        const promise = this.http.post(`${environment.freeSearchPort}FreeSearchTokenRequest/`, user, this.options).toPromise()
          .then((response: any) => {
            if (response) {
              this.showServicecall = false;
              this.expiredDate = new Date();
              let Minutes = response.data['expires_in'] * 1000; // convert 2 minutes to milliseconds
              var expirein = (Minutes - (Minutes / 10))
              let expiredate2 = new Date(this.expiredDate.getTime() + expirein);
              // ,'httpOnly':true,
              // this.cookieService.putObject('free_access_token', response.data['access_token'], { 'expires': expiredate2, 'secure':true,sameSite :'none' });
              // this.cookieService.putObject('free_ref_token', response.data['refresh_token'],{'secure':true,sameSite :'none'});
              this.ssrcookieService.setItem('free_access_token', response.data['access_token'],  expiredate2 );
              this.ssrcookieService.setItem('free_ref_token', response.data['refresh_token']);
              this.localStorage.setItem('free_ref_token', response.data['refresh_token']);
              this.localStorage.setItem('FreeRefreshTokenInProgress', 'false');
              this.behaviorService.freeSearchTokenUpdated.next(true);

      // this.behaviorService.freeSearchAccessTokenUpdated.next(response.data['access_token']);
      // this.behaviorService.freeSearchRefreshTokenUpdated.next(response.data['refresh_token']);

            }
            return
          })
        return promise;
      // }

    // }
    // catch (error) {
    // }
  }
   storeJwtToken(response:any) {
    if (response['success'] === true) {
      this.expiredDate = new Date();
      let Minutes = response.data['expires_in'] * 1000;
      var expirein = (Minutes - (Minutes / 10))
      let expiredate2 = new Date(this.expiredDate.getTime() + expirein);
      // ,'httpOnly':true
      this.ssrcookieService.setItem('free_access_token', response.data['access_token'], expiredate2);
      this.ssrcookieService.setItem('free_ref_token', response.data['refresh_token']);
      this.localStorage.setItem('free_ref_token', response.data['refresh_token']);
      this.localStorage.setItem('FreeRefreshTokenInProgress', 'false');
      this.behaviorService.freeSearchTokenUpdated.next(true);

    }
  }
checkRoutes(){
  this.behaviorService.isLoggedIn.subscribe((val) => {
    let isLoggedIn = this.isUserLoggedIn();
if(!isLoggedIn){
  if (
    this.router.url?.includes('notifications') ||
    this.router.url?.includes('dashboard') ||
    this.router.url?.includes('add-vehicle') ||
    this.router.url?.includes('change-password') ||
    this.router.url?.includes('estimation') ||
    this.router.url?.includes('profile')
  ) {
    this.router.navigateByUrl('/login')
  }
}

  });
}
}
