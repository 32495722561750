import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { environment } from "../../../../environments/environment";
@Injectable({
    providedIn: 'root'
})

export class DataService {

    constructor(private http: HttpClient,private spinnerService :NgxSpinnerService){}

    public post<T>(apiUrl: string, requestBody: any): Observable<T> {
        return this.http.post<T>(apiUrl, requestBody).pipe(catchError(this.handlError));
    }

    // getGeoLocation(data:any):Observable<any> {
    //   return this.http.post(`${environment.locationPort}`, data);
    // }

    async getGeoLocation(data:any) {
      return  await this.http.post(`${environment.locationPort}`, data)
        .toPromise().then(res => {  return res; });
    }

    async loginUserViaKeyClock(data:any) {
      return  await this.http.post(`${ environment.httpPort + environment.apiVersion + 'no_auth/login_verify_otp/'}`, data)
        .toPromise().then(res => {  return res; }).catch((error) =>{ return error});
    }

    handlError(error: HttpErrorResponse) {
        return throwError(error)
      }
}
