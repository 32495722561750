// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // httpPort: 'https://rvsf-dev.api.tatamotors',//SANDBOX DEVELOPMENT
  httpPort: 'https://mobility-cv-dev-microservices.api.tatamotors',//QK DEVELOPMENT
  // httpPort: 'https://mobility-cv-prod-microservices.api.tatamotors',//PROD DEVELOPMENT

  locationPort : 'https://skindevreplica.api.tatamotors/api/v2/ecom/search/geo_dropdowns/',  //SANDBOX DEVELOPMENT
  freeSearchPort : 'https://skindevreplica.api.tatamotors/api/v1/ecom/',  //SANDBOX DEVELOPMENT

  "rewire_sugar": "8x/A?D(G+KbPeShVkYp3s6v9y$B&E)H@",//SANDBOX DEVELOPMENT
  "rewire_salt": "v8y/B?E(H+MbQeTh",//SANDBOX DEVELOPMENT

  // "rewire_sugar": "w9z$C&E)H@McQfTjWnZr4u7x!A%D*G-J", //PROD DEVELOPMENT
  // "rewire_salt": "6v9y$B&E)H@McQfT", //PROD DEVELOPMENT

  // locationPort : 'https://skinprod.api.tatamotors/api/v2/ecom/search/geo_dropdowns/',  //PROD DEVELOPMENT
  // freeSearchPort : 'https://skinprod.api.tatamotors/api/v1/ecom/',   //PROD DEVELOPMENT
  apiVersion : '/api/v1/rvsf/',
  version: require('../../package.json').version,
  production: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
