import { isPlatformBrowser } from "@angular/common";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { SharedService } from "../api-integration-files/shared.service";
import { RootService } from "../auth/root.service";

@Injectable()
export class ErrorHandler {

 errorMsg = 'Something went wrong, Please try again !';
    constructor(
        public router: Router,
        private sharedService :SharedService,
        private rootService : RootService,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) {}

      intercept(
        req: HttpRequest<any>,
        next: HttpHandler
      ): Observable<HttpEvent<any>> {
        return next
          .handle(req)
          .pipe(catchError((error) => this.handleError(error, req)));
      }

      // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      //   return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {

          handleError(error: HttpErrorResponse, req: HttpRequest<HttpEvent<any>>) : Observable<HttpEvent<any>>{
            if (isPlatformBrowser(this.platformId)) {

              const chunkFailedMessage = /Loading chunk [\d]+ failed/;

               if (chunkFailedMessage.test(error.message)) {
                 window.location.reload();
               }
           // other stuff for error handling.
             }

            let errorMessage = '';
            if (error.status === 400 && error.error.data.error_code === 20) {
                // if (error.url == `${environment.apiUrl}api/v1/cv_online/token/refresh/`) {
                // }
                // this.storageService.logout();
                // this.router.navigate(['/']);
          this.sharedService.failedSnackBarResponse(this.errorMsg);

            }
            else if (error.status === 0) {
                // if (error.url == `${environment.apiUrl}api/v1/cv_online/token/refresh/`) {
                //     this.storageService.logout();
                //     this.router.navigateByUrl('home');
                // }
                // if (error.url !== `${environment.apiUrl}api/v1/cv_online/dashboard_lob_wise/`
                // && error.url !== `${environment.apiUrl}api/v1/cv_online/get_vehicles_info/`
                // && error.url !== `${environment.apiUrl}api/v1/cv_online/dropdowns/`
                // && error.url !== `${environment.apiUrl}api/v1/cv_online/get_vehicle_details/`) {
          this.sharedService.failedSnackBarResponse(this.errorMsg);

                // }
            }
            else if (error.status === 401) {
                this.rootService.refreshToken2();
            }
            else if (error.status === 412) {
              // if(error.error.data.msg === 'Authentication credentials were expired.'){
              // this.sharedService.failedSnackBarResponse(error.error.data.msg);
              // }

            }
            else if (error.status === 500) {
                // if (error.url == `${environment.apiUrl}api/v1/cv_online/dropdowns/` ||
                // error.url == `${environment.apiUrl}api/search/accounts/`) {
                // }
                // else {
          this.sharedService.failedSnackBarResponse('Server Error , Please Try Again.');

                // }
            }
            else if (error.status === 504) {
          this.sharedService.failedSnackBarResponse('Slow internet connection. Please try again after sometime.');
            }
            else if (error.status === 422) {
          this.sharedService.failedSnackBarResponse(error.error.message);

            }
            else if (error.status === 400) {
              this.sharedService.failedSnackBarResponse(error.error.message);

            }
            else {
                if (error.error instanceof ErrorEvent) {
                    // client-side error
                    errorMessage = `Error: ${error.error.message}`;
                } else {
                    // server-side error
                    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                }
            }

            if (error && error.error && error.error.data && error.error.data.msg) {
                // if (error.url !== `${environment.apiUrl}api/v1/cv_online/dashboard_lob_wise/` &&
                // error.url !== `${environment.apiUrl}api/v1/cv_online/get_vehicles_info/` &&
                // error.url !== `${environment.apiUrl}api/v1/cv_online/dropdowns/` &&
                // error.url !== `${environment.apiUrl}api/v1/cv_online/get_vehicle_details/`) {
                  this.sharedService.failedSnackBarResponse(error.error.message);

                // }

            }
            return throwError(errorMessage);
        // }),
        //     finalize(() => {
        //         //   this.spinner.hide ()
        //     })
        // )
    }
}
