 <ngx-spinner
bdColor = "rgba(0, 0, 0, 0.4)" size = "medium" color = "#fff" type = "ball-pulse"
[fullScreen] = "true">
<!-- <div class="la-ball-pulse la-2x">
  <div></div>
  <div></div>
  <div></div>
</div>  -->
<!-- <p style="color: white" >Loading... </p> -->

</ngx-spinner>
<router-outlet></router-outlet>

