import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
//nonssr
// import { CookieService } from "ngx-cookie";
import { BehaviorSubject } from "rxjs";
import { RootService } from "../auth/root.service";
import { secureStorage } from "../encryption/securestorage";
import { BehaviorDataService } from "./behavior-data.service";
import { CookieService } from "./cookie.service";

@Injectable({
  providedIn: 'root'
})

export class SharedService {
  userdetails: any;
  usertoken: any;
  user_code: any;
  public loginStatus: BehaviorSubject<boolean>;

  constructor(
    private snackBar: MatSnackBar,
    private behaviorService: BehaviorDataService,
    private rootService: RootService,
    private router: Router,
    private localStorage: secureStorage,
  //  private cookieService: CookieService,
   private ssrcookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: Object,) {}

  afterLogin(response: any, loginScreen: any) {
    let data = response.data;
    let tokendetails = response.data.token;
    this.userdetails = {
      keycloak_user_id: data.keycloak_user_id,
      email_id: data.email_id,
      first_name: data.first_name,
      middle_name: data.middle_name,
      last_name: data.last_name,
      phone_number: data.phone_number,
      is_email_exist  :data.is_email_exist,
      userid: data.id
    }
    this.usertoken = {
      access_token: tokendetails.access_token,
      expires_in: tokendetails.expires_in,
      refresh_token: tokendetails.refresh_token,
      refresh_expires_in: tokendetails.refresh_expires_in,
    }
    this.user_code = {
      user_code: data.user_code,
      isLoggedIn: loginScreen
    }
    // return () => {
       this.rootService.setToken(this.usertoken);
    this.rootService.setUserCode(this.user_code);
    if (loginScreen) {
      this.rootService.setUserData(this.userdetails)
      this.router.navigateByUrl('/dashboard')

      this.behaviorService.isLoggedIn.next(true);

    }
    // dealer_location_id: null
    //
    // is_active: true
    // parent: null
    // party_sub_type: "Seller"
    // party_type: "Seller"
    // user_name: "8097310188"
  }

  logout() {

      // this.cookieService.removeAll();
      //ssr
    this.ssrcookieService.removeItem('isuserLoggedIn');
    this.ssrcookieService.removeItem('access_token');
    this.ssrcookieService.removeItem('ref_token');
    this.behaviorService.isLoggedIn.next(false);
    this.behaviorService.userLoggedOut.next(true);
    this.localStorage.clear();

  }

  failedSnackBarResponse(msg: any) {
    this.snackBar.open(msg, '', {
      duration: 5000,
      panelClass: ["custom-styleRed"], verticalPosition: "bottom", // Allowed values are  'top' | 'bottom'
      horizontalPosition: "center" // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    });
  }

  successSnackBarResponse(msg: any) {
    this.snackBar.open(msg, '', {
      duration: 5000,
      panelClass: ["custom-style"], verticalPosition: "bottom", // Allowed values are  'top' | 'bottom'
      horizontalPosition: "center" // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    });
  }

  getOwners(d:any){
    if (d > 3 && d < 21) return d + "th";
    switch (d % 10) {
      case 1:  return d + "st";
      case 2:  return d + "nd";
      case 3:  return d + "rd";
      default: return d + "th";
    }
  }
}
