import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationEnd,
  NavigationStart,
  Router,
  Event as NavigationEvent,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { RootService } from './root.service';
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from '../api-integration-files/cookie.service';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorDataService } from '../api-integration-files/behavior-data.service';
import { AuthService } from './auth.service';
import { secureStorage } from '../encryption/securestorage';
@Injectable()
export class RoleGuard implements CanActivate {
  currentRoute: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private rootService: RootService,
    private ssrcookieService: CookieService,
    private behaviorService: BehaviorDataService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private localStorage : secureStorage,
    private auth : AuthService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    let role = this.rootService.isUserLoggedIn();
    // let role = this.localStorage.getItem('userCode')?.isLoggedIn ? true : false
    if(role){
      return true;
    }
    this.router.navigateByUrl('')
    return false;
    }
  }
