import { Injectable, PLATFORM_ID, Inject } from "@angular/core";
// import CryptoJS from "crypto-js";
// import {AES,enc,PBKDF2}from 'crypto-js';
import { isPlatformBrowser } from "@angular/common";
import { Router } from "@angular/router";
import { BehaviorDataService } from "../api-integration-files/behavior-data.service";
import { environment } from "src/environments/environment";
var rewire_platform = "chair@rewire$9876";
var CryptoJS = require("crypto-js");
@Injectable({ providedIn: "root" })
export class secureStorage {

    constructor(
        private behaviorService : BehaviorDataService,
        @Inject(PLATFORM_ID) private platformId: Object,
        private router: Router
    ) { }

    setItem(key: any, value: any) {
        if (isPlatformBrowser(this.platformId)) {
            let valuenew = CryptoJS.AES.encrypt(value, rewire_platform);
            localStorage.setItem(key, valuenew.toString());
            return null;
        } else {
            return null;
        }
    }

    getItem(key: any) {
        if (isPlatformBrowser(this.platformId)) {
            let valuenew = localStorage.getItem(key);
            if (valuenew) {
                let data :any = CryptoJS.AES.decrypt(valuenew, rewire_platform);
                data = data.toString(CryptoJS.enc.Utf8);
                return data;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    clear() {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.clear();
            this.router.navigateByUrl("");
            window.location.reload();
        }
    }

    encryptData(data: any) {
        var skey = environment.rewire_sugar
        var salt = environment.rewire_salt
        var iv = CryptoJS.enc.Utf8.parse(salt);
        var key = CryptoJS.enc.Utf8.parse(skey);
        var cipherpassword = CryptoJS.AES.encrypt(data, key, { iv: iv }).toString();
        return cipherpassword;
    }
    decryptData(data: any) {

      var skey = environment.rewire_sugar
      var salt = environment.rewire_salt
      var iv = CryptoJS.enc.Utf8.parse(salt);
      var key = CryptoJS.enc.Utf8.parse(skey);
      var cipherpassword = CryptoJS.AES.decrypt(data, key, { iv: iv });
      cipherpassword = cipherpassword.toString(CryptoJS.enc.Utf8);
      return cipherpassword;
    }
}
