import { isPlatformServer } from '@angular/common';
import { Injectable, Inject, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import { Request, Response } from 'express';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  cookies: any = {};
  setCookies : any = {};
  document: any = { cookie: '' };
  constructor(
    @Optional() @Inject(REQUEST) private req: Request<any>,
    @Optional() @Inject(RESPONSE) private res: Response<any>,
   @Inject(PLATFORM_ID) private platformId: Object,
  ) {
     if (this.req !== null && this.req['headers']) {
    this.cookies = this.req['headers'].cookie;
    }
     else {
      this.document = document;
    }
  }
  getServerUrl(){
  //   if (isPlatformServer(this.platformId)){
  //   return this.req.get('host') ? this.req.get('host') : '';
  // } else
  {

    return document.location.hostname;

  }
  }

  getItem(name:any): string | null {
    if(this.cookies == undefined || this.cookies == null){
      return null
    }
    const cookies: { [key: string]: string | null } = this._getPairs();
     if (name && cookies !== undefined && typeof cookies[name] !== undefined && cookies[name] !== undefined) {
      return cookies[name] == undefined || cookies[name] == null || cookies[name] == '' ? null : cookies[name];
    }
    return null;
  }

  setItem(
    name: string,
    value: string,
    expiry?: Date | string,
    path?: string
  ): boolean {
    if (!name) {
      return false;
    }

    if (!path) {
      path = '/';
    }
    if (this.req === null) {
      let expiryStr = '';
      if (expiry) {
        if (!(expiry instanceof Date)) {
          expiry = new Date(expiry);
        }
        expiryStr = '; expires=' + expiry.toUTCString();
      }
      this.document.cookie = `${name}=${value}${expiryStr}; path=${path}; Secure;SameSite=None`;
    } else {
      if (expiry) {
        if (!(expiry instanceof Date)) {
          expiry = new Date(expiry);
        }
        const dt = new Date();

        if (expiry.getTime() <= dt.getTime()) {
          this.removeItem(name, path);
        } else {
          this.res.addTrailers
          this.res.cookie(name, value, {
            expires: expiry,
            path,
            encode: String,
          });
        }
      } else {
        this.res.cookie(name, value, { path, encode: String });
      }
    }

    return true;
  }

  removeItem(name: string, path?: string): boolean {
    if (this.req !== null || !name) {
      return false;
    }

    if (!path) {
      path = '/';
    }

    if (this.req === null) {
      this.document.cookie = `${name}=;path=${path}`;
    } else {
      this.cookies[name] = null;
      // const expiry = new Date('Thu, 01 Jan 1970 00:00:00 UTC');
      this.res.cookie(name, null, {path, encode: String });
    }

    return true;
  }

  _getPairs(): { [key: string]: string | null } {
    if (this.req === null) {
      const parsed = this.document.cookie.split('; ') ;
      const cookies: { [key: string]: string | null } = {};
      +
      parsed.forEach((element: string) => {
        if (element) {
          let index = element.indexOf("=")
          let mainTokenValue = element.slice(index+1);
          const pair = element.split('=',1);
          pair.push(mainTokenValue);
          cookies[pair[0]] = typeof pair[1] !== 'undefined' || undefined ? pair[1] : null;
        }
      });
      return cookies;
    } else {
      const parsed = this.cookies.split('; ') ;
      const cookies: { [key: string]: string | null } = {};
      +
      parsed.forEach((element: string) => {
        if (element) {
          let index = element.indexOf("=")
          let mainTokenValue = element.slice(index+1);
          const pair = element.split('=',1);
          pair.push(mainTokenValue);
          cookies[pair[0]] = typeof pair[1] !== 'undefined' || undefined ? pair[1] : null;
        }
      });
      return cookies;
    }
  }
}
