import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationEnd,
  NavigationStart,
  Router,
  Event as NavigationEvent,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { RootService } from './root.service';
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from '../api-integration-files/cookie.service';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorDataService } from '../api-integration-files/behavior-data.service';
import { AuthService } from './auth.service';
@Injectable()
export class AuthGuard implements CanActivate {
  currentRoute: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private rootService: RootService,
    private ssrcookieService: CookieService,
    private behaviorService: BehaviorDataService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private auth : AuthService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const url: string = state.url;
      if(!this.auth.isLoggedIn()){
      // this.router.navigateByUrl('/dashboard');

        return true;
      }
      this.router.navigateByUrl('/dashboard');
      return false;

    // if (this.rootService.isUserLoggedIn()) {
    //   if (url == '' || url?.includes('login') || url == '/') {
    //     this.router.navigateByUrl('/dashboard');
    //     return true;
    //   } else if (url == undefined) {
    //     return true;
    //   }
    //   return true;
    // } else {
    //   if (url == '' || url?.includes('login') || url == '/') {
    //     return true;
    //   } else {
    //     if (
    //       url?.includes('notifications') ||
    //       url?.includes('dashboard') ||
    //       url?.includes('add-vehicle') ||
    //       url?.includes('change-password') ||
    //       url?.includes('estimation') ||
    //       url?.includes('profile')
    //     ) {
    //         // this.router.navigateByUrl('');

    //       // return false;
    //       this.behaviorService.userLoggedOut.subscribe((val) => {
    //         route = val;
    //         if (val) {
    //         }
    //       });
    //       if (route) {
    //         this.router.navigateByUrl('');
    //         return true;
    //       } else {
    //         this.router.navigateByUrl('');

    //         return true;
    //       }
    //     } else {

    //       this.router.navigateByUrl('');
    //     }
    //     return true;
    //   }
    // }
  }
}
