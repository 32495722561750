import { isPlatformBrowser } from "@angular/common";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Router } from "@angular/router";
// import { CookieService } from "ngx-cookie";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { tap, map, catchError, switchMap, filter, take } from 'rxjs/operators'
import { BehaviorDataService } from "../api-integration-files/behavior-data.service";
import { CookieService } from "../api-integration-files/cookie.service";
import { SharedService } from "../api-integration-files/shared.service";
import { RootService } from "../auth/root.service";
import { secureStorage } from "../encryption/securestorage";
@Injectable()

export class APIHttpInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    freeaccessToken:any
    freerefreshToken:any;
    isLoggedIn: boolean = false;
    private FreeTokenInProgress = false;
    private FreeTokenSubject: Subject<any> = new BehaviorSubject<any>(null);
    private FreeRefreshTokenInProgress = false;
    private FreeRefreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);
    constructor(
        private localStorage: secureStorage,
        private sharedService: SharedService,
        private rootService: RootService,
        //nonssr
      //  private cookieService: CookieService,
       //ssr
       private ssrcookieService: CookieService,
        private behaviorService :BehaviorDataService,
        @Inject(PLATFORM_ID) private platformId: Object,) { }

    injectToken(request: HttpRequest<any>) {
        // let isLoggedIn = this.rootService.isUserLoggedIn();
        const userCode  :any = this.rootService.getUserCode() ? this.rootService.getUserCode() : null;
        // const accessToken :any = this.cookieService.getObject('access_token') ? this.cookieService.getObject('access_token') : null;
        const accessToken :any = this.ssrcookieService.getItem('access_token') ? this.ssrcookieService.getItem('access_token') : null;
        this.behaviorService.freeSearchTokenUpdated.subscribe((val:any) => {

        //  this.freeaccessToken = this.cookieService.getObject('free_access_token') ? this.cookieService.getObject('free_access_token') : null;
        // this.freerefreshToken = this.cookieService.getObject('free_ref_token') ? this.cookieService.getObject('free_ref_token') : this.localStorage.getItem('free_ref_token');
        this.freeaccessToken = this.ssrcookieService.getItem('free_access_token') ? this.ssrcookieService.getItem('free_access_token') : null;
        this.freerefreshToken = this.ssrcookieService.getItem('free_ref_token') ? this.ssrcookieService.getItem('free_ref_token') : this.localStorage.getItem('free_ref_token');
      })

        if(request.url.includes('geo_dropdown') || request.url.includes('FreeSearchTokenRequest')){
        if (this.freeaccessToken && this.freerefreshToken) {
            return request.clone({
                setHeaders: {
                    // 'user-code': userCode,
                    Authorization: `Bearer ${this.freeaccessToken}`
                },
            });
        }
        else {
            return request;
        }
        }else{
        if (accessToken && userCode) {

            return request.clone({
                setHeaders: {
                    'user-code': userCode,
                    Authorization: `Bearer ${accessToken}`
                },
            });

        }
        else {
            return request;
        }
    }
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.url.indexOf('refresh') !== -1) {
            return next.handle(this.injectToken(request)).pipe(catchError(this.handleError));
        }

        // const accessToken = this.cookieService.getObject('access_token') ? this.cookieService.getObject('access_token') : null ;
        // const refreshToken = this.cookieService.getObject('ref_token') ? this.cookieService.getObject('ref_token') : null;

        const accessToken = this.ssrcookieService.getItem('access_token') ? this.ssrcookieService.getItem('access_token') : null ;
        const refreshToken = this.ssrcookieService.getItem('ref_token') ? this.ssrcookieService.getItem('ref_token') : null;
        //for freesearch token
        // const accessExpired :any = this.cookieService.getObject('free_access_token') ? this.cookieService.getObject('free_access_token') : null;
        // const refreshExpired  :any= this.cookieService.getObject('free_ref_token') ? this.cookieService.getObject('free_ref_token')  :  this.localStorage.getItem('free_ref_token');

        const accessExpired :any = this.ssrcookieService.getItem('free_access_token') ? this.ssrcookieService.getItem('free_access_token') : null;
        const refreshExpired  :any= this.ssrcookieService.getItem('free_ref_token') ? this.ssrcookieService.getItem('free_ref_token')  :  this.localStorage.getItem('free_ref_token');


        if(request.url.includes('geo_dropdown') || request.url.includes('FreeSearchTokenRequest')){
            if (accessExpired && refreshExpired) {
                return next.handle(this.injectToken(request)).pipe(catchError(this.handleError));
              }
              if (!accessExpired && !refreshExpired) {
                if (!this.FreeTokenInProgress) {
                  this.FreeTokenInProgress = true;
                  this.FreeTokenSubject.next(null);
                } else {
                  this.isLoggedIn = this.rootService.isUserLoggedIn();
                  if (this.isLoggedIn) {
                    if (!this.FreeRefreshTokenInProgress) {
                      this.FreeRefreshTokenInProgress = true;
                      this.FreeRefreshTokenSubject.next(null);
                      if (!accessExpired) {
                        if (
                          this.localStorage.getItem('FreeRefreshTokenInProgress') != 'true'
                        ) {
                          this.localStorage.setItem('FreeRefreshTokenInProgress', 'true');
                          let res :any = this.rootService.refreshToken2();
                          return res.pipe(
                            switchMap((authResponse:any) => {
                              if (authResponse) {
                                this.FreeRefreshTokenInProgress = false;
                              }
                              this.FreeRefreshTokenSubject.next(
                                authResponse.data.refresh_token
                              );

                              return next.handle(this.injectToken(request)).pipe(catchError(this.handleError));
                            })
                          );
                        }
                      } else {
                      }
                    } else {
                      if (!accessExpired) {
                        if (
                          this.localStorage.getItem('FreeRefreshTokenInProgress') != 'true'
                        ) {
                          this.localStorage.setItem('FreeRefreshTokenInProgress', 'true');
                          // return
                           this.rootService.refreshToken2();
                        }
                      }
                    }
                  } else {
        if (isPlatformBrowser(this.platformId)) {

                    window.location.reload();
        }
                  }
                }
              }
              if (!accessExpired && refreshExpired) {
                if (!this.FreeRefreshTokenInProgress) {
                  this.FreeRefreshTokenInProgress = true;
                  this.FreeRefreshTokenSubject.next(null);
                  if (!accessExpired) {
                    if (this.localStorage.getItem('FreeRefreshTokenInProgress') != 'true') {
                      this.localStorage.setItem('FreeRefreshTokenInProgress', 'true');
                      let res :any = this.rootService.refreshToken2();
                          return res.pipe(switchMap((authResponse:any) => {
                          if (authResponse) {
                            this.FreeRefreshTokenInProgress = false;
                          }
                          this.FreeRefreshTokenSubject.next(authResponse.data.refresh_token);
                          return next.handle(this.injectToken(request)).pipe(catchError(this.handleError));
                        })
                      );
                    }
                  } else {
                  }
                } else {
                  return this.FreeRefreshTokenSubject.pipe(
                    filter((result) => result !== null),
                    take(1),
                    switchMap((res) => {
                      return next.handle(this.injectToken(request)).pipe(catchError(this.handleError));
                    })
                  );
                }
              }
              if (!accessExpired) {
                return next.handle(this.injectToken(request)).pipe(catchError(this.handleError));
              }
        }
        else{

          //normal token
        if (accessToken && refreshToken) {
            return next.handle(this.injectToken(request)).pipe(catchError(this.handleError));
        }
        if (!accessToken && !refreshToken) {
            let isLoggedIn = this.rootService.isUserLoggedIn();;
            if (isLoggedIn) {

              this.sharedService.logout();


    // if (isPlatformBrowser(this.platformId)) {

                // this.localStorage.clear();
                // this.cookieService.removeAll();
    // }
                // if (!this.isRefreshing) {
                //     this.isRefreshing = true;
                //     this.refreshTokenSubject.next(null);
                //     return this.rootService.refreshToken().pipe(
                //         switchMap((authResponse: any) => {
                //             this.isRefreshing = false;
                //             this.refreshTokenSubject.next(authResponse.data.refresh_token);
                //             return next.handle(this.injectToken(request)).pipe(catchError(this.handleError));
                //         }));
                // }
            } else {
                return next.handle(this.injectToken(request)).pipe(catchError(this.handleError));
            }
        }
        if (!accessToken && refreshToken) {
            if (!this.isRefreshing) {
                this.isRefreshing = true;
                this.refreshTokenSubject.next(null);
                return this.rootService.refreshToken().pipe(
                    switchMap((authResponse: any) => {
                        this.isRefreshing = false;
                        this.refreshTokenSubject.next(authResponse.data.refresh_token);
                        return next.handle(this.injectToken(request)).pipe(catchError(this.handleError));
                    })
                );
            }
            else {
                return this.refreshTokenSubject.pipe(
                    filter((token) => token !== null),
                    take(1),
                    switchMap((res) => {
                        return next.handle(this.injectToken(request)).pipe(catchError(this.handleError));
                    })
                );
            }
        }
        if (!accessToken) {
            return next.handle(this.injectToken(request)).pipe(catchError(this.handleError))
        }
    }
        return next.handle(this.injectToken(request)).pipe(catchError(this.handleError));


    }

    handleError(error: HttpErrorResponse) {

        let data: any = {};
            let errorMsg = 'Something went wrong, Please try again !';
            data = {
            msg: error && error.error && error.error.data ? error.error.data.msg : error && error.error && error.error == '<h1>403 Forbidden</h1>' ? errorMsg : error,
            status: error.status,
            apierror : error,
            // netWorkStatus: window.navigator.onLine
            netWorkStatus: true
        };
// if( data.msg == 'Authentication credentials were expired.'){
//     this.sharedService.logout();
// }
//         // this.errorDialogService.openDialog(data);
//         if (error.status === 401) {
//     if (isPlatformBrowser(this.platformId)) {

//             this.localStorage.clear();
//             throw data;
//     }
//         }
        if(error.status === 0 || error.status === 500 ){
            data.msg = 'Something went wrong, Please try again !'
             return throwError(data);
          }
        if(!data.netWorkStatus){
            data.msg = "You are Offline.Please check the network connectivity";
            return throwError(data);
        }
        return throwError(data);
    }


}


