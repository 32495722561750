import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APIHttpInterceptor } from './shared/services/interceptors/http.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { CookieModule, CookieService } from 'ngx-cookie';
import { ErrorHandler } from './shared/services/interceptors/error_handler';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RootService } from './shared/services/auth/root.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CookieService } from './shared/services/api-integration-files/cookie.service';
import { RoleGuard } from './shared/services/auth/role.guard';
// import { APP_BASE_HREF } from '@angular/common';
export function init_app(
  rootService: RootService,
) {
  return () => rootService.upgradeVersion();
  // return () => rootService.getFreeSearchToken();

}
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    // CookieModule.forRoot(),
    BrowserAnimationsModule,MatSnackBarModule,
    NgxSpinnerModule,
  ],
  providers: [
    AuthGuard,RoleGuard,
    ErrorHandler,
    { provide: HTTP_INTERCEPTORS,
    useClass: APIHttpInterceptor,
    multi: true,
  },
  // { provide: HTTP_INTERCEPTORS, useClass: ErrorHandler, multi: true },

    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [RootService,
        //ssrcookie
        CookieService
      ],
      multi: true
    },
    // { provide: APP_BASE_HREF, useValue: "/RVSF/" },
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
