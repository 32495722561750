import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
@Injectable({
    providedIn: "root"
})

export class ApiUrlList {


    constructor(){}
    apiUrlList: any[] = [
        {apiUrl: environment.httpPort + environment.apiVersion + 'no_auth/refresh_token/', id: 'refreshToken'},
        {apiUrl: environment.httpPort + environment.apiVersion + 'no_auth/login/', id: 'login'},
        {apiUrl: environment.httpPort + environment.apiVersion + 'no_auth/registration/', id: 'register'},
        {apiUrl: environment.httpPort + environment.apiVersion + 'no_auth/validate/', id: 'validateUserInputs'},
        {apiUrl: environment.httpPort + environment.apiVersion + 'no_auth/send_otp/', id: 'sendOTP'},
        {apiUrl: environment.httpPort + environment.apiVersion + 'no_auth/login_send_otp/', id: 'keyclock_sendotp'},
        {apiUrl: environment.httpPort + environment.apiVersion + 'no_auth/login_verify_otp/', id: 'keyclock_verifyotp'},
        {apiUrl: environment.httpPort + environment.apiVersion + 'no_auth/reset_password/', id: 'resetPassword'},
        {apiUrl: environment.httpPort + environment.apiVersion + 'account/update_email/', id: 'updateEmailId'},
        {apiUrl: environment.httpPort + environment.apiVersion + 'account/profile/', id: 'profile'},
        {apiUrl: environment.httpPort + environment.apiVersion + 'no_auth/send_enquiry/', id: 'contactUs'},
        {apiUrl: environment.httpPort + environment.apiVersion + 'account/notifications/', id: 'notifications'},
        {apiUrl: environment.httpPort + environment.apiVersion + 'account/change_password/', id: 'changePassword'},
        {apiUrl: environment.httpPort + environment.apiVersion + 'deal/calculate_price_range/', id: 'dealPrice'},
        {apiUrl: environment.httpPort + environment.apiVersion + 'deal/user_interest/', id: 'userIntrest'},
        {apiUrl: environment.httpPort + environment.apiVersion + 'no_auth/dropdown/', id: 'dropdown'},
        {apiUrl: environment.httpPort + environment.apiVersion + 'deal/get_user_vehicle_list/', id: 'userVehicleList'},
        {apiUrl: environment.httpPort + environment.apiVersion + 'deal/insert_vehicle_address/', id: 'addAddress'},
        {apiUrl: environment.httpPort + environment.apiVersion + 'deal/add_bank_details/', id: 'addBankDetails'},
        {apiUrl: environment.httpPort + environment.apiVersion + 'no_auth/check_dealer/', id: 'checkDealer'},
        {apiUrl: environment.httpPort + environment.apiVersion + 'account/logout/', id: 'logout'}




    ]

    returnAPI(id: String){
        let fetchedAPI = this.apiUrlList.find(x => x.id === id);
        return fetchedAPI.apiUrl;
    }
}
