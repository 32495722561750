import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import("./core/layout/layout.module").then(m => m.LayoutModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: "enabled",
    scrollPositionRestoration: "enabled",
    onSameUrlNavigation: "reload",
    initialNavigation: "enabled"
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
