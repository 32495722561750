import { Injectable } from "@angular/core";
import { secureStorage } from "../encryption/securestorage";
import { RootService } from "./root.service";

@Injectable({
  providedIn: 'root'
})

export class  AuthService {
  constructor(
    private localStorage : secureStorage,
    private rootService : RootService
  ){

  }
  isLoggedIn(){
    let isLogin = this.rootService.isUserLoggedIn();

    // let isLogin = this.localStorage.getItem('userCode')?.isLoggedIn ? true : false;
    return !!isLogin
  }
}
